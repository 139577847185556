import { DEFAULT_PATHS } from '../config';

export const ACL = {
  superAdmin: {
    // Please DON'T REMOVE KEYS FROM SUPERADMIN for disabling COMMENT THEM.
    '/': { add: 1, delete: 1, update: 1 },
    '/dashboard': { add: 1, delete: 1, update: 1 },
    '/dashboard/getting-started': { add: 1, delete: 1, update: 1, enableDashboard: 1 },
    '/dashboard/ratings-and-reviews': { add: 1, delete: 1, update: 1 },
    '/dashboard/messages': { add: 1, delete: 1, update: 1 },
    '/dashboard/messages/detail/': { add: 1, delete: 1, update: 1 },
    '/dashboard/sales-analysis': { add: 1, delete: 1, update: 1 },
    '/dashboard/delivery-monitoring': { add: 1, delete: 1, update: 1 },
    '/dashboard/product-analysis': { add: 1, delete: 1, update: 1 },
    '/dashboard/order-monitoring': { add: 1, delete: 1, update: 1 },
    '/dashboard/delayed-confirmation': { add: 1, delete: 1, update: 1 },
    '/dashboard/product-monitoring': { add: 1, delete: 1, update: 1 },
    '/sellers': { add: 1, delete: 1, update: 1 },
    '/sellers/list': { add: 1, delete: 1, update: 1 },
    '/sellers/audit': { add: 1, delete: 1, update: 1 },
    '/sellers/detail/': { add: 1, delete: 1, update: 1 },
    '/catalog': { add: 1, delete: 1, update: 1 },
    '/catalog/pending-approvals': { add: 1, delete: 1, update: 1 },
    '/catalog/products-pending-qc': { add: 1, delete: 1, update: 1 },
    '/catalog/attributes-pending-qc': { add: 1, delete: 1, update: 1 },
    '/catalog/pending-image-approvals': { add: 1, delete: 1, update: 1 },
    '/catalog/product-images': { add: 1, delete: 1, update: 1 },
    '/catalog/partner': { add: 1, delete: 1, update: 1 },
    '/catalog/partner/add': { add: 1, delete: 1, update: 1 },
    '/catalog/partner/detail/': { add: 1, delete: 1, update: 1 },
    '/catalog/pending-products': { add: 1, delete: 1, update: 1 },
    '/catalog/archive-products': { add: 1, delete: 1, update: 1 },
    '/catalog/rejected-images': { add: 1, delete: 1, update: 1 },
    '/catalog/offers': { add: 1, delete: 1, update: 1 },
    '/catalog/offers/detail/': { add: 1, delete: 1, update: 1 },
    '/catalog/offers/create': { add: 1, delete: 1, update: 1 },
    '/catalog/categories': { add: 1, delete: 1, update: 1 },
    '/catalog/brands': { add: 1, delete: 1, update: 1 },
    '/catalog/fields': { add: 1, delete: 1, update: 1 },
    '/catalog/fields/detail/': { add: 1, delete: 1, update: 1 },
    '/catalog/field-sets': { add: 1, delete: 1, update: 1 },
    '/catalog/field-sets/detail/': { add: 1, delete: 1, update: 1 },
    '/catalog/inventory': { add: 1, delete: 1, update: 1 },
    '/catalog/inventory/detail/': { add: 1, delete: 1, update: 1 },
    '/catalog/import': { add: 1, delete: 1, update: 1 },
    '/catalog/import-history': { add: 1, delete: 1, update: 1 },
    '/catalog/audit-logs': { add: 1, delete: 1, update: 1 },
    '/catalog/magento-sync': { add: 1, delete: 1, update: 1 },
    '/catalog/product-feed': { add: 1, delete: 1, update: 1 },
    '/catalog/ratings-and-reviews': { add: 1, delete: 1, update: 1 },
    '/catalog/question-and-answer': { add: 1, delete: 1, update: 1 },
    '/orders': { add: 1, delete: 1, update: 1 },
    '/orders/views/all': { add: 1, delete: 1, update: 1 },
    '/orders/views/pending': { add: 1, delete: 1, update: 1 },
    '/orders/views/packing': { add: 1, delete: 1, update: 1 },
    '/orders/views/shipping_pending': { add: 1, delete: 1, update: 1 },
    '/orders/views/shipped': { add: 1, delete: 1, update: 1 },
    '/orders/views/out_for_delivery': { add: 1, delete: 1, update: 1 },
    '/orders/views/delivered': { add: 1, delete: 1, update: 1 },
    '/orders/views/undelivered': { add: 1, delete: 1, update: 1 },
    '/orders/views/invoice_pending': { add: 1, delete: 1, update: 1 },
    '/orders/views/completed': { add: 1, delete: 1, update: 1 },
    '/orders/views/rejected': { add: 1, delete: 1, update: 1 },
    '/orders/views/cancelled': { add: 1, delete: 1, update: 1 },
    '/orders/detail/': { add: 1, delete: 1, update: 1 },
    '/returns': { add: 1, delete: 1, update: 1 },
    '/returns/returns': { add: 1, delete: 1, update: 1 },
    '/returns/detail/': { add: 1, delete: 1, update: 1 },
    '/promotions': { add: 1, delete: 1, update: 1 },
    '/promotions/list': { add: 1, delete: 1, update: 1 },
    '/promotions/list/auto-applied': { add: 1, delete: 1, update: 1 },
    '/promotions/list/auto-applied/detail/': { add: 1, delete: 1, update: 1 },
    '/promotions/list/coupon': { add: 1, delete: 1, update: 1 },
    '/promotions/list/coupon/detail/': { add: 1, delete: 1, update: 1 },
    '/promotions/list/coupon-logs': { add: 1, delete: 1, update: 1 },
    '/promotions/list/campaigns': { add: 1, delete: 1, update: 1 },
    '/promotions/list/campaigns/detail/': { add: 1, delete: 1, update: 1 },
    '/reports': { add: 1, delete: 1, update: 1 },
    '/statements': { add: 1, delete: 1, update: 1 },
    '/statements/overview': { add: 1, delete: 1, update: 1 },
    '/statements/dashboard': { add: 1, delete: 1, update: 1 },
    '/statements/transactions': { add: 1, delete: 1, update: 1, auditVefiy: 1 },
    '/statements/all': { add: 1, delete: 1, update: 1 },
    '/statements/current-settlement': { add: 1, delete: 1, update: 1 },
    '/statements/current-statement': { add: 1, delete: 1, update: 1 },
    '/statements/completed': { add: 1, delete: 1, update: 1 },
    '/statements/transactions/detail/': { add: 1, delete: 1, update: 1 },
    '/statements/order-payments': { add: 1, delete: 1, update: 1 },
    '/errors/list': { add: 1, delete: 1, update: 1 },
    '/errors/list/all': { add: 1, delete: 1, update: 1 },
    '/studio': { add: 1, delete: 1, update: 1 },
    '/studio/partner': { add: 1, delete: 1, update: 1 },
    '/studio/studio-images': { add: 1, delete: 1, update: 1 },
    '/studio/unlisted-products': { add: 1, delete: 1, update: 1 },
    '/studio/ideas': { add: 1, delete: 1, update: 1 },
    '/b2b-promotions': { add: 1, delete: 1, update: 1 },
    '/b2b-promotions/banners/slots': { add: 1, delete: 1, update: 1 },
    '/b2b-promotions/banners/pending-approval': { add: 1, delete: 1, update: 1 },
    '/consignments': { add: 1, delete: 1, update: 1 },
    '/consignments/lpo-list': { add: 1, delete: 1, update: 1 },
    '/consignments/lpo-details': { add: 1, delete: 1, update: 1 },
    '/consignments/lpo-details/': { add: 1, delete: 1, update: 1 },
    '/consignments/lpo-list/lpo-details/': { add: 1, delete: 1, update: 1 },
    '/catalog/ideas': { add: 1, delete: 1, update: 1 },
    '/catalog/ideas/detail/': { add: 1, delete: 1, update: 1 },
    '/dashboard/crm': { add: 1, delete: 1, update: 1 },
    '/dashboard/crm/detail/': { add: 1, delete: 1, update: 1 },
    '/dashboard/account-health': { add: 1, delete: 1, update: 1 },
    '/dashboard/account-health/detail/': { add: 1, delete: 1, update: 1 },
    '/dashboard/delivery-track-sheet': { add: 1, delete: 1, update: 1 },
    '/dashboard/delivery-track-sheet-easy-ship': { add: 1, delete: 1, update: 1 },
    '/penalty': { add: 1, delete: 1, update: 1 },
    '/penalty/penalties': { add: 1, delete: 1, update: 1 },
    '/penalty/list': { add: 1, delete: 1, update: 1 },
    '/marketing': { add: 1, delete: 1, update: 1 },
    '/marketing/campaigns': { add: 1, delete: 1, update: 1 },
    '/marketing/campaigns/detail/': { add: 1, delete: 1, update: 1 },
  },
  finance: {
    '/': { add: 1, delete: 1, update: 1 },
    '/dashboard': { add: 1, delete: 1, update: 1 },
    '/dashboard/getting-started': { add: 1, delete: 1, update: 1, enableDashboard: 1 },
    '/dashboard/messages': { add: 1, delete: 1, update: 1 },
    '/dashboard/messages/detail/': { add: 1, delete: 1, update: 1 },

    '/orders': { add: 1, delete: 1, update: 1 },
    '/orders/views/all': { add: 1, delete: 1, update: 1 },
    '/orders/views/pending': { add: 1, delete: 1, update: 1 },
    '/orders/views/packing': { add: 1, delete: 1, update: 1 },
    '/orders/views/shipping_pending': { add: 1, delete: 1, update: 1 },
    '/orders/views/shipped': { add: 1, delete: 1, update: 1 },
    '/orders/views/delivered': { add: 1, delete: 1, update: 1 },
    '/orders/views/invoice_pending': { add: 1, delete: 1, update: 1 },
    '/orders/views/out_for_delivery': { add: 1, delete: 1, update: 1 },
    '/orders/views/undelivered': { add: 1, delete: 1, update: 1 },
    '/orders/views/completed': { add: 1, delete: 1, update: 1 },
    '/orders/views/rejected': { add: 1, delete: 1, update: 1 },
    '/orders/views/cancelled': { add: 1, delete: 1, update: 1 },
    '/orders/detail/': { add: 1, delete: 1, update: 1 },
    '/returns': { add: 1, delete: 1, update: 1 },
    '/returns/returns': { add: 1, delete: 1, update: 1 },
    '/returns/detail/': { add: 1, delete: 1, update: 1 },

    '/reports': { add: 1, delete: 1, update: 1 },
    '/statements': { add: 1, delete: 1, update: 1 },
    '/statements/overview': { add: 1, delete: 1, update: 1 },
    '/statements/dashboard': { add: 1, delete: 1, update: 1 },
    '/statements/transactions': { add: 1, delete: 1, update: 1 },
    '/statements/all': { add: 1, delete: 1, update: 1 },
    '/statements/current-settlement': { add: 1, delete: 1, update: 1 },
    '/statements/current-statement': { add: 1, delete: 1, update: 1 },
    '/statements/completed': { add: 1, delete: 1, update: 1 },
    '/statements/transactions/detail/': { add: 1, delete: 1, update: 1 },
    '/statements/order-payments': { add: 1, delete: 1, update: 1 },
    '/errors/list': { add: 1, delete: 1, update: 1 },
    '/errors/list/all': { add: 1, delete: 1, update: 1 },
    '/dashboard/delivery-track-sheet': { add: 1, delete: 1, update: 1 },
    '/dashboard/delivery-track-sheet-easy-ship': { add: 1, delete: 1, update: 1 },
    '/penalty': { add: 1, delete: 1, update: 1 },
    '/penalty/penalties': { add: 1, delete: 1, update: 1 },
    '/penalty/list': { add: 1, delete: 1, update: 1 },
  },

  accounts: {
    '/': { add: 1, delete: 1, update: 1 },
    '/dashboard': { add: 1, delete: 1, update: 1 },
    '/dashboard/getting-started': { add: 1, delete: 1, update: 1, enableDashboard: 1 },
    '/dashboard/messages': { add: 1, delete: 1, update: 1 },
    '/dashboard/messages/detail/': { add: 1, delete: 1, update: 1 },

    '/dashboard/ratings-and-reviews': { add: 1, delete: 1, update: 1 },

    '/dashboard/sales-analysis': { add: 1, delete: 1, update: 1 },
    '/dashboard/delivery-monitoring': { add: 1, delete: 1, update: 1 },
    '/dashboard/product-analysis': { add: 1, delete: 1, update: 1 },
    '/dashboard/order-monitoring': { add: 1, delete: 1, update: 1 },
    '/dashboard/delayed-confirmation': { add: 1, delete: 1, update: 1 },
    '/dashboard/product-monitoring': { add: 1, delete: 1, update: 1 },

    '/orders': { add: 1, delete: 1, update: 1 },
    '/orders/views/all': { add: 1, delete: 1, update: 1 },
    '/orders/views/pending': { add: 1, delete: 1, update: 1 },
    '/orders/views/packing': { add: 1, delete: 1, update: 1 },
    '/orders/views/shipping_pending': { add: 1, delete: 1, update: 1 },
    '/orders/views/shipped': { add: 1, delete: 1, update: 1 },
    '/orders/views/delivered': { add: 1, delete: 1, update: 1 },
    '/orders/views/out_for_delivery': { add: 1, delete: 1, update: 1 },
    '/orders/views/invoice_pending': { add: 1, delete: 1, update: 1 },
    '/orders/views/completed': { add: 1, delete: 1, update: 1 },
    '/orders/views/rejected': { add: 1, delete: 1, update: 1 },
    '/orders/views/cancelled': { add: 1, delete: 1, update: 1 },
    '/orders/detail/': { add: 1, delete: 1, update: 1 },
    '/returns': { add: 1, delete: 1, update: 1 },
    '/returns/returns': { add: 1, delete: 1, update: 1 },
    '/returns/detail/': { add: 1, delete: 1, update: 1 },

    '/reports': { add: 1, delete: 1, update: 1 },
    '/statements': { add: 1, delete: 1, update: 1 },
    '/statements/overview': { add: 1, delete: 1, update: 1 },
    '/statements/dashboard': { add: 1, delete: 1, update: 1 },
    '/statements/transactions': { add: 1, delete: 1, update: 1 },
    '/statements/all': { add: 1, delete: 1, update: 1 },
    '/statements/current-settlement': { add: 1, delete: 1, update: 1 },
    '/statements/current-statement': { add: 1, delete: 1, update: 1 },
    '/statements/completed': { add: 1, delete: 1, update: 1 },
    '/statements/transactions/detail/': { add: 1, delete: 1, update: 1 },
    '/statements/order-payments': { add: 1, delete: 1, update: 1 },
    '/errors/list': { add: 1, delete: 1, update: 1 },
    '/errors/list/all': { add: 1, delete: 1, update: 1 },
    '/penalty': { add: 1, delete: 1, update: 1 },
    '/penalty/penalties': { add: 1, delete: 1, update: 1 },
    '/penalty/list': { add: 1, delete: 1, update: 1 },
  },

  audit: {
    '/': { add: 1, delete: 1, update: 1 },
    '/dashboard': { add: 1, delete: 1, update: 1 },
    '/dashboard/getting-started': { add: 1, delete: 1, update: 1, enableDashboard: 1 },
    '/dashboard/messages': { add: 1, delete: 1, update: 1 },
    '/dashboard/messages/detail/': { add: 1, delete: 1, update: 1 },

    '/dashboard/ratings-and-reviews': { add: 1, delete: 1, update: 1 },

    '/dashboard/sales-analysis': { add: 1, delete: 1, update: 1 },
    '/dashboard/delivery-monitoring': { add: 1, delete: 1, update: 1 },
    '/dashboard/product-analysis': { add: 1, delete: 1, update: 1 },
    '/dashboard/order-monitoring': { add: 1, delete: 1, update: 1 },
    '/dashboard/delayed-confirmation': { add: 1, delete: 1, update: 1 },
    '/dashboard/product-monitoring': { add: 1, delete: 1, update: 1 },

    '/orders': { add: 1, delete: 1, update: 1 },
    '/orders/views/all': { add: 1, delete: 1, update: 1 },
    '/orders/views/pending': { add: 1, delete: 1, update: 1 },
    '/orders/views/packing': { add: 1, delete: 1, update: 1 },
    '/orders/views/shipping_pending': { add: 1, delete: 1, update: 1 },
    '/orders/views/shipped': { add: 1, delete: 1, update: 1 },
    '/orders/views/delivered': { add: 1, delete: 1, update: 1 },
    '/orders/views/out_for_delivery': { add: 1, delete: 1, update: 1 },
    '/orders/views/invoice_pending': { add: 1, delete: 1, update: 1 },
    '/orders/views/completed': { add: 1, delete: 1, update: 1 },
    '/orders/views/rejected': { add: 1, delete: 1, update: 1 },
    '/orders/views/cancelled': { add: 1, delete: 1, update: 1 },
    '/orders/detail/': { add: 1, delete: 1, update: 1 },
    '/returns': { add: 1, delete: 1, update: 1 },
    '/returns/returns': { add: 1, delete: 1, update: 1 },
    '/returns/detail/': { add: 1, delete: 1, update: 1 },

    '/reports': { add: 1, delete: 1, update: 1 },
    '/statements': { add: 1, delete: 1, update: 1 },
    '/statements/overview': { add: 1, delete: 1, update: 1 },
    '/statements/dashboard': { add: 1, delete: 1, update: 1 },
    '/statements/transactions': { add: 1, delete: 1, update: 1, auditVefiy: 1 },
    '/statements/all': { add: 1, delete: 1, update: 1 },
    '/statements/current-settlement': { add: 1, delete: 1, update: 1 },
    '/statements/current-statement': { add: 1, delete: 1, update: 1 },
    '/statements/completed': { add: 1, delete: 1, update: 1 },
    '/statements/transactions/detail/': { add: 1, delete: 1, update: 1 },
    '/statements/order-payments': { add: 1, delete: 1, update: 1 },
    '/errors/list': { add: 1, delete: 1, update: 1 },
    '/errors/list/all': { add: 1, delete: 1, update: 1 },
    '/dashboard/account-health': { add: 1, delete: 1, update: 1 },
    '/dashboard/account-health/detail/': { add: 1, delete: 1, update: 1 },
    '/penalty': { add: 1, delete: 1, update: 1 },
    '/penalty/penalties': { add: 1, delete: 1, update: 1 },
    '/penalty/list': { add: 1, delete: 1, update: 1 },
  },
  studioAdmin: {
    '/': { add: 1, delete: 1, update: 1 },
    '/dashboard': { add: 1, delete: 1, update: 1 },
    '/dashboard/getting-started': { add: 1, delete: 1, update: 1, enableDashboard: 0 },
    '/studio': { add: 1, delete: 1, update: 1 },
    '/studio/partner': { add: 1, delete: 1, update: 1 },
    '/studio/partner/add': { add: 1, delete: 1, update: 1 },
    '/studio/partner/detail/': { add: 1, delete: 1, update: 1 },

    '/catalog': { add: 0, delete: 0, update: 0 },
    '/catalog/pending-approvals': { add: 0, delete: 0, update: 0 },
    '/catalog/products-pending-qc': { add: 0, delete: 0, update: 0 },
    '/catalog/attributes-pending-qc': { add: 0, delete: 0, update: 0 },
    '/catalog/pending-image-approvals': { add: 0, delete: 0, update: 0 },
    '/catalog/partner': { add: 0, delete: 0, update: 0 },
    '/catalog/partner/add': { add: 0, delete: 0, update: 0 },
    '/catalog/partner/detail/': { add: 0, delete: 0, update: 0 },
    '/catalog/offers': { add: 0, delete: 0, update: 0 },
    '/catalog/offers/detail/': { add: 0, delete: 0, update: 0 },
    '/catalog/offers/create': { add: 0, delete: 0, update: 0 },
    '/catalog/categories': { add: 0, delete: 0, update: 0 },
    '/catalog/brands': { add: 0, delete: 0, update: 0 },
    '/catalog/fields': { add: 0, delete: 0, update: 0 },
    '/catalog/fields/detail/': { add: 0, delete: 0, update: 0 },
    '/catalog/field-sets': { add: 0, delete: 0, update: 0 },
    '/catalog/field-sets/detail/': { add: 0, delete: 0, update: 0 },
    '/catalog/inventory': { add: 0, delete: 0, update: 0 },
    '/catalog/inventory/detail/': { add: 0, delete: 0, update: 0 },
    '/catalog/import': { add: 0, delete: 0, update: 0 },
    '/catalog/import-history': { add: 0, delete: 0, update: 0 },
    '/catalog/audit-logs': { add: 0, delete: 0, update: 0 },
    '/catalog/magento-sync': { add: 0, delete: 0, update: 0 },
    '/catalog/product-feed': { add: 0, delete: 0, update: 0 },
    '/catalog/ratings-and-reviews': { add: 0, delete: 0, update: 0 },
    '/catalog/question-and-answer': { add: 0, delete: 0, update: 0 },
  },
  warehouse: {
    '/': { add: 1, delete: 1, update: 1 },
    '/catalog': { add: 0, delete: 0, update: 0 },
    '/catalog/all-products': { add: 1, delete: 1, update: 1 },
    '/catalog/pending-products': { add: 1, delete: 1, update: 1 },
  },
  catalog: {
    '/': { add: 1, delete: 1, update: 1 },
    '/catalog': { add: 0, delete: 0, update: 0 },
    '/catalog/partner': { add: 1, delete: 1, update: 1 },
    '/catalog/partner/detail/': { add: 1, delete: 1, update: 1 },
    '/catalog/pending-approvals': { add: 1, delete: 1, update: 1 },
    '/catalog/products-pending-qc': { add: 1, delete: 1, update: 1 },
    '/catalog/attributes-pending-qc': { add: 1, delete: 1, update: 1 },
    '/catalog/pending-image-approvals': { add: 1, delete: 1, update: 1 },
    '/catalog/product-images': { add: 1, delete: 1, update: 1 },
    '/catalog/import': { add: 1, delete: 1, update: 1 },
  },
  marketting: {
    '/dashboard': { add: 1, delete: 1, update: 1 },
    '/dashboard/getting-started': { add: 1, delete: 1, update: 1, enableDashboard: 0 },
    '/dashboard/ratings-and-reviews': { add: 1, delete: 1, update: 1 },
    '/dashboard/messages': { add: 1, delete: 1, update: 1 },
    '/dashboard/messages/detail/': { add: 1, delete: 1, update: 1 },
    '/dashboard/sales-analysis': { add: 1, delete: 1, update: 1 },
    '/dashboard/delivery-monitoring': { add: 1, delete: 1, update: 1 },
    '/dashboard/product-analysis': { add: 1, delete: 1, update: 1 },
    '/dashboard/order-monitoring': { add: 1, delete: 1, update: 1 },
    '/dashboard/delayed-confirmation': { add: 1, delete: 1, update: 1 },
    '/dashboard/product-monitoring': { add: 1, delete: 1, update: 1 },

    '/promotions': { add: 1, delete: 1, update: 1 },
    '/promotions/list': { add: 1, delete: 1, update: 1 },
    '/promotions/list/auto-applied': { add: 1, delete: 1, update: 1 },
    '/promotions/list/auto-applied/detail/': { add: 1, delete: 1, update: 1 },
    '/promotions/list/coupon': { add: 1, delete: 1, update: 1 },
    '/promotions/list/coupon/detail/': { add: 1, delete: 1, update: 1 },
    '/promotions/list/coupon-logs': { add: 1, delete: 1, update: 1 },
  },
  customerSupport: {
    '/': { add: 1, delete: 1, update: 1 },
    '/dashboard': { add: 1, delete: 1, update: 1 },
    '/dashboard/getting-started': { add: 1, delete: 1, update: 1, enableDashboard: 0 },
    '/dashboard/ratings-and-reviews': { add: 1, delete: 1, update: 1 },
    '/dashboard/delayed-confirmation': { add: 1, delete: 1, update: 1 },
    '/orders': { add: 1, delete: 1, update: 1 },
    '/orders/views/all': { add: 1, delete: 1, update: 1 },
    '/orders/views/pending': { add: 1, delete: 1, update: 1 },
    '/orders/views/packing': { add: 1, delete: 1, update: 1 },
    '/orders/views/shipping_pending': { add: 1, delete: 1, update: 1 },
    '/orders/views/shipped': { add: 1, delete: 1, update: 1 },
    '/orders/views/delivered': { add: 1, delete: 1, update: 1 },
    '/orders/views/out_for_delivery': { add: 1, delete: 1, update: 1 },
    '/orders/views/invoice_pending': { add: 1, delete: 1, update: 1 },
    '/orders/views/completed': { add: 1, delete: 1, update: 1 },
    '/orders/views/rejected': { add: 1, delete: 1, update: 1 },
    '/orders/views/cancelled': { add: 1, delete: 1, update: 1 },
    '/orders/detail/': { add: 1, delete: 1, update: 1 },
    '/returns': { add: 1, delete: 1, update: 1 },
    '/returns/returns': { add: 1, delete: 1, update: 1 },
    '/returns/detail/': { add: 1, delete: 1, update: 1 },
    '/dashboard/messages': { add: 1, delete: 1, update: 1 },
    '/dashboard/messages/detail/': { add: 1, delete: 1, update: 1 },

    '/catalog': { add: 0, delete: 0, update: 0 },
    '/catalog/pending-approvals': { add: 0, delete: 0, update: 0 },
    '/catalog/products-pending-qc': { add: 0, delete: 0, update: 0 },
    '/catalog/attributes-pending-qc': { add: 0, delete: 0, update: 0 },
    '/catalog/pending-image-approvals': { add: 0, delete: 0, update: 0 },
    '/catalog/product-images': { add: 1, delete: 1, update: 1 },
    '/catalog/partner': { add: 1, delete: 1, update: 1 },
    '/catalog/partner/add': { add: 1, delete: 1, update: 1 },
    '/catalog/partner/detail/': { add: 1, delete: 1, update: 1 },
    '/catalog/pending-products': { add: 1, delete: 1, update: 1 },
    '/catalog/archive-products': { add: 1, delete: 1, update: 1 },
    '/catalog/rejected-images': { add: 1, delete: 1, update: 1 },
    '/catalog/offers': { add: 0, delete: 0, update: 0 },
    '/catalog/offers/detail/': { add: 1, delete: 1, update: 1 },
    '/catalog/offers/create': { add: 1, delete: 1, update: 1 },
    '/catalog/categories': { add: 1, delete: 1, update: 1 },
    '/catalog/brands': { add: 0, delete: 0, update: 0 },
    '/catalog/fields': { add: 0, delete: 0, update: 0 },
    '/catalog/fields/detail/': { add: 0, delete: 0, update: 0 },
    '/catalog/field-sets': { add: 1, delete: 1, update: 1 },
    '/catalog/field-sets/detail/': { add: 0, delete: 0, update: 0 },
    '/catalog/inventory': { add: 1, delete: 1, update: 1 },
    '/catalog/inventory/detail/': { add: 1, delete: 1, update: 1 },
    '/catalog/import': { add: 1, delete: 1, update: 1 },
    '/catalog/import-history': { add: 1, delete: 1, update: 1 },
    '/catalog/audit-logs': { add: 1, delete: 1, update: 1 },

    '/catalog/ratings-and-reviews': { add: 1, delete: 1, update: 1 },
    '/catalog/question-and-answer': { add: 1, delete: 1, update: 1 },
    // '/dashboard/crm': { add: 1, delete: 1, update: 1 },
    // '/dashboard/crm/detail/': { add: 1, delete: 1, update: 1 },
    // '/dashboard/sales-analysis': { add: 1, delete: 1, update: 1 },
    '/dashboard/delivery-monitoring': { add: 1, delete: 1, update: 1 },

    '/dashboard/account-health': { add: 1, delete: 1, update: 1 },
    '/dashboard/account-health/detail/': { add: 1, delete: 1, update: 1 },
    '/dashboard/delivery-track-sheet': { add: 1, delete: 1, update: 1 },
    '/dashboard/delivery-track-sheet-easy-ship': { add: 1, delete: 1, update: 1 },
  },
};

export const BYPASS_ROUTES = [DEFAULT_PATHS.SUPPORT_TICKET_WIDGET, DEFAULT_PATHS.PRODUCT_RECOMMENDATION];

export const getPrivilege = (role, routePath) => {
  console.log('role', role, routePath);
  if (role) {
    let modifiedRoute = routePath;
    if (routePath.includes('/detail/')) {
      const routeSplit = routePath.split('/');
      routeSplit.pop();
      routePath.indexOf('/detail/');
      modifiedRoute = routePath.substr(0, routePath.indexOf('/detail/') + 8); // `${routeSplit.join('/')}/`;
    }
    const privilegeKeys = Object.keys(ACL[role] || {});
    const privilegeIndex = privilegeKeys.find((p) => p === modifiedRoute);
    const privilege = ACL[role]?.[privilegeIndex];
    if (privilege) {
      return privilege;
    }
    return false;
  }
  return false;
};

export const enablePrivilege = (privilege, type) => {
  if (privilege && Object.keys(privilege)?.length > 0) {
    if (privilege[type]) {
      return true;
    }
    return false;
  }
  return false;
};
